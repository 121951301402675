import PropTypes from 'prop-types';
import React, { useEffect } from 'react';
import { OSMMap } from 'volto-venue';
import { useDispatch, useSelector } from 'react-redux';
import { getContent, resetContent } from '@plone/volto/actions';
import { flattenToAppURL } from '@plone/volto/helpers';

/**
 * BibliotecaMap view component class.
 * @function BibliotecaMap
 * @params {object} content: Content object.
 * @returns {string} Markup of the component.
 */
const BibliotecaMap = ({ uffici_responsabili = [] }) => {
  const dispatch = useDispatch();
  const fetchedLocations = useSelector((state) => state.content.subrequests);
  const venues = uffici_responsabili.map((location) => ({
    key: location['@id'],
    url: flattenToAppURL(location['@id']),
  }));
  useEffect(() => {
    // eslint-disable-next-line no-unused-expressions
    uffici_responsabili?.forEach((loc) => {
      dispatch(getContent(flattenToAppURL(loc['@id']), null, loc['@id']));
    });

    return () =>
      uffici_responsabili?.forEach((loc) => {
        dispatch(resetContent(loc['@id']));
      });
  }, [dispatch, uffici_responsabili]);

  let venuesData = venues?.reduce((acc, val) => {
    let venue = fetchedLocations?.[val.key]?.data;
    if (venue?.geolocation?.latitude && venue?.geolocation?.longitude) {
      return [
        ...acc,
        {
          latitude: venue.geolocation.latitude,
          longitude: venue.geolocation.longitude,
          title: venue.title,
        },
      ];
    }

    return acc;
  }, []);
  return venuesData?.length > 0 ? (
    <div className={'fullwidth-map'}>
      {__CLIENT__ && (
        <OSMMap
          center={[venuesData[0].latitude, venuesData[0].longitude]}
          markers={venuesData}
          showTooltip
        />
      )}
    </div>
  ) : null;
};

BibliotecaMap.propTypes = {
  locations: PropTypes.arrayOf(
    PropTypes.shape({
      '@id': PropTypes.string,
      '@type': PropTypes.string,
      title: PropTypes.string,
      description: PropTypes.string,
      review_state: PropTypes.string,
    }),
  ),
};

export default BibliotecaMap;
