import React from 'react';
// eslint-disable-next-line import/no-unresolved
import logo from '@package/components/ImolaTheme/Logo/logo.png?width=200';

const LogoFooter = () => (
  <figure className="footer-logo icon">
    <img src={logo} alt="" loading="lazy" width="200px" height="92.85px" />
  </figure>
);

export default LogoFooter;
