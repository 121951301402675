import React from 'react';
import BibliotecaMap from '@package/components/ImolaTheme/Blocks/Map/BibliotecaMap';
import { Row, Col, Container } from 'design-react-kit/dist/design-react-kit';

const Body = ({ data, inEditMode, path, onChangeBlock }) => {
  return (
    <Container>
      {data.title && (
        <Row>
          <Col>
            <h2 className="mb-4">{data.title}</h2>
          </Col>
        </Row>
      )}
      <BibliotecaMap uffici_responsabili={data.venues} />
    </Container>
  );
};
export default Body;
