import React from 'react';

const BodyWrapper = ({ children }) => {
  return (
    <div>
      <div className="d-flex flex-wrap scopri-contenuti-wrap justify-content-center">
        {children}
      </div>
    </div>
  );
};
export default BodyWrapper;
