import mapSVG from '@package/icons/map-marked-alt.svg';
import linkSvg from '@plone/volto/icons/link.svg';
import noteSvg from 'bootstrap-italia/src/svg/it-note.svg';
import MapView from '@package/components/ImolaTheme/Blocks/Map/View';
import MapEdit from '@package/components/ImolaTheme/Blocks/Map/Edit';
import SuggerimentiBibliotecaEdit from '@package/components/ImolaTheme/Blocks/SuggerimentiBiblioteca/Edit';
import SuggerimentiBibliotecaView from '@package/components/ImolaTheme/Blocks/SuggerimentiBiblioteca/View';
import EventoView from '@italia/components/ItaliaTheme/View/EventoView/EventoView';
import ScopriContenutiView from '@package/components/ImolaTheme/Blocks/ScopriContenuti/View';
import ScopriContenutiEdit from '@package/components/ImolaTheme/Blocks/ScopriContenuti/Edit';
import ArgumentListingTemplateReggio from '@package/components/ImolaTheme/Blocks/Listing/SquareImageListingTemplate';
import ImageGalleryListingBlockTemplate from '@package/components/ImolaTheme/Blocks/Listing/ImageGallery';
import ChiediAlBibliotecarioView from '@package/components/ImolaTheme/Views/ChiediAlBibliotecarioView/ChiediAlBibliotecarioView';
import NewsletterView from '@package/components/ImolaTheme/Views/NewsletterView/NewsletterView';
import SearchInCatalogsView from '@package/components/ImolaTheme/Blocks/SearchInCatalogs/View';
import SearchInCatalogsEdit from '@package/components/ImolaTheme/Blocks/SearchInCatalogs/Edit';

import applyItaliaConfig from '@italia/config/italiaConfig';
import '@plone/volto/config';

export default function applyConfig(voltoConfig) {
  let config = applyItaliaConfig(voltoConfig);

  // const iconList = Object.keys(Icons.fas).map((icon) => Icons[icon]);
  // const iconListRegular = Object.keys(IconsRegular.far).map(
  //   (icon) => IconsRegular[icon],
  // );
  // library.add(...iconList, ...iconListRegular);

  config.settings.supportedLanguages = ['it'];
  config.settings.defaultLanguage = 'it';
  config.settings.defaultExcludedFromSearch = {
    portalTypes: ['Image'],
  };

  config.views.contentTypesViews.Rassegna = EventoView;

  config.views.layoutViews.chiedi_al_bibliotecario_view = ChiediAlBibliotecarioView;
  config.views.layoutViews.newsletter_view = NewsletterView;

  config.blocks.blocksConfig = {
    ...config.blocks.blocksConfig,
    map: {
      id: 'map',
      title: 'Mappa degli uffici',
      icon: mapSVG,
      group: 'homePage',
      view: MapView,
      edit: MapEdit,
      restricted: false,
      mostUsed: false,
      security: {
        addPermission: [],
        view: [],
      },
      sidebarTab: 1,
    },
    suggerimenti_biblioteca: {
      id: 'suggerimenti_biblioteca',
      title: 'Cose da fare',
      icon: noteSvg,
      group: 'homePage',
      view: SuggerimentiBibliotecaView,
      edit: SuggerimentiBibliotecaEdit,
      restricted: false,
      mostUsed: false,
      security: {
        addPermission: [],
        view: [],
      },
      sidebarTab: 1,
    },
    scopriContenuti: {
      id: 'scopriContenuti',
      title: 'Scopri i contenuti',
      icon: linkSvg,
      group: 'homePage',
      view: ScopriContenutiView,
      edit: ScopriContenutiEdit,
      restricted: false,
      mostUsed: false,
      security: {
        addPermission: [],
        view: [],
      },
      sidebarTab: 1,
    },
    searchInCatalogs: {
      id: 'searchInCatalogs',
      title: 'Cerca nei cataloghi',
      icon: linkSvg,
      group: 'homePage',
      view: SearchInCatalogsView,
      edit: SearchInCatalogsEdit,
      restricted: false,
      mostUsed: false,
      security: {
        addPermission: [],
        view: [],
      },
      sidebarTab: 1,
    },
    listing: {
      ...config.blocks.blocksConfig.listing,
      templates: {
        ...config.blocks.blocksConfig.listing.templates,
        argumentListingTemplateReggio: {
          label: 'Lista di immagini quadrate',
          template: ArgumentListingTemplateReggio,
        },
        imageGallery: {
          label: 'Image gallery',
          template: ImageGalleryListingBlockTemplate,
        },
      },
    },
  };

  config.settings.siteProperties = {
    ...config.settings.siteProperties,
    siteTitle: 'Biblioteca comunale di Imola',
    siteSubtitle: '',
    parentSiteTitle: 'Comune di Imola',
    parentSiteURL: 'https://www.comune.imola.bo.it',
    subsiteParentSiteTitle: 'Biblioteca comunale di Imola',
    // amministrazioneTrasparenteUrl: '/amministrazione-trasparente',
    //arLoginUrl: 'https://io-comune.agamar.redturtle.it/login',
    // arLogoutUrl: 'https://io-comune.agamar.redturtle.it/logout',
    //spidLogin: true, //se true, nella pagina di errore Unauthorized, mostra il pulsante per il login a Spid.
    // headerslimTertiaryMenu: {
    //   it: [
    //     //{ title: 'Contatti', url: '/it/contatti' },
    //     //{ title: 'Novità', url: '/it/novita' },
    //   ],
    //   en: [
    //     //{ title: 'Contacts', url: '/en/contacts' },
    //     //{ title: 'News', url: '/en/news' },
    //   ],
    // },
    enableCustomerSatisfaction: false,
    splitMegamenuColumns: false, //se impostato a false, non spezza le colonne con intestazioni nel megamenu
  };

  config.settings.defaultExcludedFromSearch = {
    portalTypes: ['Image'],
  };

  config.settings.matomoSiteId = 'yw3WarlqgQ';
  config.settings.matomoUrlBase = 'https://ingestion.webanalytics.italia.it/';

  config.settings['volto-gdpr-privacy'].defaultPanelConfig.last_updated =
    '2023-03-06T13:13:00+00:00';
  config.settings['volto-gdpr-privacy'].defaultPanelConfig.text = {
    //Text that is shown when the banner appears
    it: {
      title: 'Usiamo i cookies',
      description:
        "Questo sito utilizza i cookie tecnici di navigazione e di sessione per garantire un miglior servizio di navigazione del sito, e cookie analitici per raccogliere informazioni sull'uso del sito da parte degli utenti.  Utilizza anche cookie di profilazione dell'utente per fini statistici. I cookie di profilazione puoi decidere se abilitarli o meno cliccando sul pulsante 'Cambia le impostazioni'. Per saperne di più su come disabilitare i cookie oppure abilitarne solo alcuni, consulta la nostra <a href='/privacy' target='_blank'>Cookie Policy.</a>",
    },
    en: {
      title: 'We use cookies',
      description:
        "This site uses technical navigation and session cookies to ensure a better site navigation service, and analytical cookies to collect information on the use of the site by users. It also uses user profiling cookies for statistical and remarketing purposes. For profiling cookies you can decide whether to enable them or not by clicking on the 'Change settings' button. To find out more, on how to disable cookies or enable only some of them, consult our <a href='/privacy' target='_blank'>Cookie Policy</a>.",
    },
    fr: {
      title: 'We use cookies',
      description:
        "Ce site utilise des cookies techniques de navigation et de session pour assurer un meilleur service de navigation sur le site, et des cookies analytiques pour collecter des informations sur l'utilisation du site par les utilisateurs. Il utilise également des cookies de profilage des utilisateurs à des fins statistiques et de remarketing. Pour les cookies de profilage, vous pouvez décider de les activer ou non en cliquant sur le bouton « Modifier les paramètres ». Pour en savoir plus, sur la façon de désactiver les cookies ou d'en activer seulement certains, consultez notre <a href='/privacy' target='_blank'>Politique des cookies</a>.",
    },
  };
  config.settings['volto-gdpr-privacy'].defaultPanelConfig.technical.choices = [
    {
      config_key: 'MATOMO',
      text: {
        it: {
          title: 'Web Analytics Italia',
          description:
            "I cookie di Web Analytics Italia sono usati per analizzare la navigazione sul sito al fine di migliorarla e fornire all'utente un'esperienza di navigazione migliore possibile.",
        },
        en: {
          title: 'Web Analytics Italia',
          description:
            'Web Analytics Italia cookie are used to analyze navigation on the site in order to improve it and provide the user with the best possible browsing experience.',
        },
        fr: {
          title: 'Web Analytics Italia',
          description:
            "Les cookie de Web Analytics Italia sont utilisés pour analyser la navigation sur le site afin de l'améliorer et offrir à l'utilisateur la meilleure expérience de navigation possible.",
        },
      },
    },
    ...config.settings[
      'volto-gdpr-privacy'
    ].defaultPanelConfig.technical.choices.filter(
      (f) => f.config_key !== 'GANALYTICS' && f.config_key !== 'GRECAPTCHA',
    ),
  ];

  return config;
}
