/**
 * Root reducer.
 * @module reducers/root
 */

import defaultReducers from '@italia/reducers';
import { postBibliotecarioContactFormReducer } from './bibliotecarioContactFormReducer';
import config from '@plone/volto/registry';
import { bimFooterReducer } from './bimFooterReducer';
import sitemap from './sitemap';
/**
 * Root reducer.
 * @function
 * @param {Object} state Current state.
 * @param {Object} action Action to be handled.
 * @returns {Object} New state.
 */
const reducers = {
  ...defaultReducers,
  ...config.addonReducers,
  // Add your reducers here
  bibliotecarioContactForm: postBibliotecarioContactFormReducer,
  bimFooter: bimFooterReducer,
  sitemap,
};

export default reducers;
