import { defineMessages } from 'react-intl';

import {
  templatesOptions,
  addDefaultOptions,
} from '@italia/config/Blocks/ListingOptions';
import { addLighthouseField } from '@italia/config/Blocks/ListingOptions/utils';

const messages = defineMessages({
  always_show_image: {
    id: 'always_show_image',
    defaultMessage: "Mostra l'immagine per tutti gli elementi",
  },
  natural_image_size: {
    id: 'natural_image_size',
    defineMessages: "Non alterare le dimensioni naturali dell'immagine",
  },
  show_topics: {
    id: 'show_topics',
    defaultMessage: 'Mostra gli argomenti',
  },
  show_hour: {
    id: 'show_hour',
    defaultMessage: "Mostra l'orario dell'evento",
  },
  show_location: {
    id: 'show_location',
    defaultMessage: "Mostra la sede dell'evento",
  },
  show_category: {
    id: 'show_category',
    defaultMessage: 'Mostra il tipo',
  },
  set_four_columns: {
    id: 'set_four_columns',
    defaultMessage: 'Disponi su 4 colonne',
  },
});

export const addCardWithImageTemplateOptions = (
  schema,
  formData,
  intl,
  position = 0,
) => {
  let pos = position;
  pos = addLighthouseField(schema, intl, pos);

  pos = addDefaultOptions(schema, formData, intl, pos);

  pos = templatesOptions(
    schema,
    formData,
    intl,
    [
      'always_show_image',
      'natural_image_size',
      'set_four_columns',
      'show_icon',
      'show_category',
      'show_section',
      'hide_dates',
      'show_hours',
      'show_location',
      'show_description',
      'show_topics',
    ],
    {
      always_show_image: {
        default: false,
        label: intl.formatMessage(messages.always_show_image),
      },
      natural_image_size: {
        default: false,
        label: intl.formatMessage(messages.natural_image_size),
      },
      set_four_columns: {
        default: false,
        label: intl.formatMessage(messages.set_four_columns),
      },
      show_section: { default: false },
      hide_dates: { default: false },
      show_hours: {
        default: false,
        label: intl.formatMessage(messages.show_hour),
      },
      show_location: {
        default: false,
        label: intl.formatMessage(messages.show_location),
      },
      show_category: {
        default: false,
        label: intl.formatMessage(messages.show_category),
      },
      show_topics: { label: intl.formatMessage(messages.show_topics) },
    },
    pos,
  );
  return pos;
};
