/**
 * Customization
 * Added background image
 */
import React from 'react';
import Image from '@plone/volto/components/theme/Image/Image';

const Background = ({ block }) => (
  <div className="searchSections-background">
    {block?.backgroundImage?.length > 0 && (
      <Image
        image={block.backgroundImage[0]['@id']}
        key={block.backgroundImage[0]['@id']}
        alt=""
        aria-hidden="true"
        loading="lazy"
        useOriginal={false}
        role="presentation"
      />
    )}
  </div>
);

export default Background;
