/*
  CUSTOMIZATIONS:
  - custom filters
  - rewrite of dots and pagination handling for react-slick edge case
    with too many events causing too many dots being shown and overlapping
    with calendar body.
    Added accessible pagination arrows when total results cannot be contained
    in 8 pages (dots) in all responsive cases and fixed month name generation.
*/

import React, {
  useCallback,
  useEffect,
  useState,
  useMemo,
  useRef,
} from 'react';
import {
  Card,
  Row,
  Col,
  Container,
  Button,
} from 'design-react-kit/dist/design-react-kit';
import { useWindowSize } from 'helpers';
import Slider from 'react-slick';
import cx from 'classnames';
import { getCalendarResults, setOriginalQuery } from '@italia/actions';
import { useDispatch, useSelector } from 'react-redux';
import Item from '@italia/components/ItaliaTheme/Blocks/Calendar/Item';
import { useIntl, defineMessages } from 'react-intl';
import { viewDate } from '@italia/helpers';
import useDeepCompareEffect from 'use-deep-compare-effect';
import {
  ListingLinkMore,
  FontAwesomeIcon,
} from '@italia/components/ItaliaTheme';

const MAX_DOTS = 8;

const messages = defineMessages({
  insert_filter: {
    id: 'insert_filter',
    defaultMessage:
      'Inserire un filtro dal menù laterale per visualizzare i relativi risultati',
  },
  calendar_no_results: {
    id: 'calendar_no_results',
    defaultMessage: 'Nessun evento disponibile al momento',
  },
  calendar_next_arrow: {
    id: 'calendar_next_arrow',
    defaultMessage: 'Prossimo',
  },
  calendar_prev_arrow: {
    id: 'calendar_prev_arrow',
    defaultMessage: 'Precedente',
  },
});

const copyFields = ['limit', 'query', 'sort_on', 'sort_order', 'depth'];

// generate chunks for pagination
function* chunks(arr, start = 0, n) {
  for (let i = start; i < arr.length; i += n) {
    yield arr.slice(i, i + n);
  }
}

const Body = ({ data, block, inEditMode, path, onChangeBlock }) => {
  const intl = useIntl();
  const windowSize = useWindowSize();
  const [activePage, setActivePage] = useState(0);
  const [batch, setBatch] = useState(0);
  const [additionalFilters, setAdditionalFilters] = useState([]);

  let currentLocationFilter = additionalFilters
    ?.filter((f) => {
      return f.i === 'event_location';
    })
    ?.map((f) => {
      return f.v;
    });
  const [locationFilter, setLocationFilter] = useState(
    currentLocationFilter?.[0] || null,
  );

  const calendarResults = useSelector(
    (state) => state.calendarSearch.subrequests,
  );
  const originalQuery = useSelector((state) =>
    state.originalQuery?.[block]?.[block]?.toArray?.(),
  );

  const items = useMemo(() => {
    const items = calendarResults[block]?.items;
    let b_size = 32;
    if (windowSize.width === 1025) {
      b_size = 24;
    } else if (windowSize.width <= 1024) {
      b_size = 16;
    }
    if (!items || items?.length === 0) return [];
    else return [...chunks(calendarResults[block]?.items, 0, b_size)];
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [calendarResults[block]?.items, windowSize.width]);

  const dispatch = useDispatch();

  const getMonth = useCallback(() => {
    if (!items[batch] || items[batch]?.length === 0) return '';
    let slidesToShow = 4;
    if (windowSize.width === 1025) {
      slidesToShow = 3;
    } else if (windowSize.width <= 1024) {
      slidesToShow = 2;
    }
    const startIndex = activePage;
    const months = [...items[batch]]
      .splice(startIndex, slidesToShow)
      ?.reduce((total, date) => {
        const month = viewDate(intl.locale, date, 'MMMM');
        if (!total.includes(month)) {
          total.push(month);
        }
        return total;
      }, []);

    return months
      ?.map((m) => m.charAt(0).toUpperCase() + m.slice(1))
      .join(' / ');
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [activePage, block, items, data.b_size, intl.locale, batch]);

  const [monthName, setMonthName] = useState(getMonth);

  const querystring = data.querystring || data; // For backwards compat with data saved before Blocks schema
  const hasQuery = querystring?.query?.length > 0;

  //set original query on loading component
  useEffect(() => {
    if (!originalQuery && block && hasQuery) {
      dispatch(
        setOriginalQuery(
          block,
          block,
          JSON.parse(JSON.stringify(querystring.query)),
        ),
      );
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  let query = [
    ...(originalQuery && additionalFilters.length > 0
      ? JSON.parse(JSON.stringify(originalQuery))
      : querystring.query ?? []),
  ];
  //faccio l'override dei filtri di default
  additionalFilters.forEach((filter) => {
    let replaced = false;
    query.forEach((f) => {
      if (f.i === filter.i && f.o === filter.o) {
        replaced = true;
        f.v = filter.v;
      }
    });
    if (!replaced) {
      query.push(filter);
    }
  });
  let _querystring = { ...querystring, query: query };
  const adaptedQuery = Object.assign(
    {
      fullobjects: 1,
    },
    ...copyFields.map((name) =>
      Object.keys(_querystring).includes(name)
        ? { [name]: _querystring[name] }
        : {},
    ),
  );

  useDeepCompareEffect(() => {
    if (hasQuery || additionalFilters.length > 0) {
      dispatch(getCalendarResults(path, adaptedQuery, block));
    } else {
      dispatch(
        getCalendarResults(
          path,
          {
            query: [
              {
                i: 'portal_type',
                o: 'plone.app.querystring.operation.selection.any',
                v: ['Event'],
              },
            ],
            fullobjects: 1,
          },
          block,
        ),
      );
    }
  }, [adaptedQuery, block, hasQuery, path, dispatch, additionalFilters]);

  // Every time the page changes check the name of the month, and
  // update the month name when the call to getCalendarResults returns
  useEffect(() => {
    setMonthName(getMonth);
  }, [activePage, items, getMonth]);

  // Update dots to show active page = 0 on batch change
  useEffect(() => {
    setActivePage(0);
    if (sliderRef?.current) sliderRef.current.slickGoTo(0);
  }, [batch]);

  const addFilters = (filters = []) => {
    setAdditionalFilters(filters);
    setBatch(0);
  };

  const settings = {
    dots: true,
    arrows: true,
    nextArrow: (
      <FontAwesomeIcon
        title={intl.formatMessage(messages.calendar_next_arrow)}
        icon={['fas', 'chevron-right']}
      />
    ),
    prevArrow: (
      <FontAwesomeIcon
        title={intl.formatMessage(messages.calendar_prev_arrow)}
        icon={['fas', 'chevron-left']}
      />
    ),
    speed: 500,
    slidesToShow: data.b_size || 4,
    slidesToScroll: data.b_size || 4,
    infinite: false,
    initialSlide: 0,
    dotsClass: 'slick-dots dot',
    lazyLoad: true,
    afterChange: (current, next) => setActivePage(current),
    appendDots: (dots) => {
      // dots custom pagination handling
      const { width } = windowSize;
      let b_size = 32;
      if (width === 1025) {
        b_size = 24;
      } else if (width <= 1024) {
        b_size = 16;
      }

      if (items?.length > 1) {
        if (
          batch === 0 &&
          b_size * (batch + 1) < calendarResults[block]?.items?.length
        ) {
          return (
            <ul>
              {dots}
              <button
                onClick={() => {
                  setBatch(batch + 1);
                }}
                tabIndex="0"
                className="calendar-pagination-button"
              >
                {settings.nextArrow}
              </button>
            </ul>
          );
        } else if (
          b_size * (batch + 1) >=
          calendarResults[block]?.items?.length
        ) {
          return (
            <ul>
              <button
                onClick={() => {
                  setBatch(batch - 1);
                }}
                tabIndex="0"
                className="calendar-pagination-button"
              >
                {settings.prevArrow}
              </button>
              {dots}
            </ul>
          );
        } else {
          return (
            <ul>
              <button
                onClick={() => {
                  setBatch(batch - 1);
                }}
                tabIndex="0"
                className="calendar-pagination-button"
              >
                {settings.prevArrow}
              </button>
              {dots}
              <button
                onClick={() => {
                  setBatch(batch + 1);
                }}
                tabIndex="0"
                className="calendar-pagination-button"
              >
                {settings.nextArrow}
              </button>
            </ul>
          );
        }
      } else return <ul>{dots}</ul>;
    },
    responsive: [
      {
        breakpoint: 1025,
        settings: {
          slidesToShow: 3,
          slidesToScroll: 3,
          dots: true,
        },
      },
      {
        breakpoint: 1024,
        settings: {
          slidesToShow: 2,
          slidesToScroll: 2,
          initialSlide: 2,
        },
      },
      {
        breakpoint: 600,
        settings: {
          dots: false,
          slidesToShow: 1,
          slidesToScroll: 1,
        },
      },
    ],
  };

  const location_filters_buttons =
    data.show_location_filters && data.location_filters
      ? Object.keys(data.location_filters)
          .map((k) => {
            return {
              label: data.location_filters[k].label,
              location: data.location_filters[k].location?.[0],
            };
          })
          .filter((f) => f.location)
      : null;

  const addLocationFilter = (location) => {
    let new_location = locationFilter === location ? null : location;
    setLocationFilter(new_location);
    let filters = [];
    if (new_location) {
      filters = [
        {
          i: 'event_location',
          o: 'plone.app.querystring.operation.selection.any',
          v: new_location,
        },
      ];
    }
    addFilters(filters);
  };
  const sliderRef = useRef(null);

  return (
    <div
      className={cx('full-width', {
        'bg-light py-5': data.show_block_bg,
        'public-ui': inEditMode,
        [data.bg_color]: data.bg_color,
      })}
    >
      <Container className="px-4">
        {(data.title || location_filters_buttons) && (
          <Row
            className={cx('template-header', {
              'with-filters': location_filters_buttons,
            })}
          >
            {data.title && (
              <Col md={location_filters_buttons ? 6 : 12}>
                <h2
                  className={cx('', {
                    'mt-5': !data.show_block_bg,
                    'mb-4': !location_filters_buttons,
                  })}
                >
                  {data.title}
                </h2>
              </Col>
            )}
            {location_filters_buttons && (
              <Col md={data.title ? 6 : 12} className="path-filter-buttons">
                <div className="path-filter-buttons-wrapper">
                  {location_filters_buttons.map((button, i) => (
                    <Button
                      key={i}
                      color="primary"
                      outline={button.location['UID'] !== locationFilter}
                      size="xs"
                      icon={false}
                      tag="button"
                      onClick={(e) => {
                        addLocationFilter(button.location['UID']);
                      }}
                    >
                      {button.label}
                    </Button>
                  ))}
                </div>
              </Col>
            )}
          </Row>
        )}
        <Card className="card-bg rounded">
          <div className="text-center calendar-header rounded-top">
            <h3>{monthName || <span>&nbsp;</span>}</h3>
          </div>
          <div className="calendar-body">
            {calendarResults[block]?.items?.length > 0 ? (
              <Slider {...settings} ref={sliderRef}>
                {items[batch]?.map((day, index) => (
                  <div key={index} className="body">
                    <Item
                      day={day}
                      query={adaptedQuery}
                      path={path}
                      inEditMode={inEditMode}
                    />
                  </div>
                ))}
              </Slider>
            ) : inEditMode ? (
              <span className="no-results">
                {intl.formatMessage(messages.insert_filter)}
              </span>
            ) : (
              <span className="no-results">
                {intl.formatMessage(messages.calendar_no_results)}
              </span>
            )}
          </div>
        </Card>
      </Container>
      <ListingLinkMore title={data.linkTitle} href={data.linkHref} />
    </div>
  );
};
export default Body;
