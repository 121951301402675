/**
 * PaginaArgomentoView view component.
 * @module components/theme/View/PaginaArgomentoView/PaginaArgomentoView
 */

import React, { useEffect, useState } from 'react';
import { hasBlocksData } from '@plone/volto/helpers';
import { Link } from 'react-router-dom';
import {
  Input,
  Form,
  FormGroup,
  Label,
  Button,
} from 'design-react-kit/dist/design-react-kit';
import { flattenToAppURL } from '@plone/volto/helpers';
import { getContent, resetContent } from '@plone/volto/actions';
import { useDispatch, useSelector } from 'react-redux';
import { postBibliotecarioContactForm } from '../../../../actions';
import { defineMessages, useIntl } from 'react-intl';

/**
 * ContactForm view component class.
 * @function ContactForm
 * @params {object} content Content object.
 * @returns {string} Markup of the component.
 */

const messages = defineMessages({
  unknownBlock: {
    id: 'Unknown Block',
    defaultMessage: 'Unknown Block {block}',
  },
  question: {
    id: 'question',
    defaultMessage:
      'Formula la tua domanda nel modo più chiaro possibile: ci aiuterai a dare una risposta più mirata',
  },
  email: {
    id: 'email',
    defaultMessage: 'Inserisci il tuo indirizzo e-mail',
  },
  name: {
    id: 'name',
    defaultMessage: 'Il tuo nome e cognome',
  },
  phone: {
    id: 'phone',
    defaultMessage: 'Un tuo recapito telefonico',
  },
  residence: {
    id: 'residence',
    defaultMessage: 'Dove risiedi abitualmente',
  },
  age: {
    id: 'age',
    defaultMessage: 'Quanti anni hai',
  },
  reason: {
    id: 'reason',
    defaultMessage: 'Scopo della ricerca',
  },
  sources: {
    id: 'sources',
    defaultMessage: 'Fonti già consultate',
  },
  privacy: {
    id: 'privacy',
    defaultMessage: 'Ho letto e accetto',
  },
  privacy_link: {
    id: 'privacy_link',
    defaultMessage: "l'informativa sulla privacy *",
  },
  send: {
    id: 'send',
    defaultMessage: 'Invia',
  },
  sent: {
    id: 'sent',
    defaultMessage: 'La richiesta è stata inviata',
  },
  requiredField: {
    id: 'requiredField',
    defaultMessage: 'Campo obbligatorio',
  },
});
const ContactForm = ({ formSubmitted, setFormSubmitted }) => {
  const intl = useIntl();
  const [email, setEmail] = useState('');
  const [question, setQuestion] = useState('');
  const [name, setName] = useState('');
  const [phone, setPhone] = useState('');
  const [residence, setResidence] = useState('');
  const [age, setAge] = useState('');
  const [reason, setReason] = useState('');
  const [sources, setSources] = useState('');
  const [privacy, setPrivacy] = useState(false);
  const [submitting, setSubmitting] = useState(false);
  const [validatedFields, setValidatedFields] = useState({
    email: false,
    question: false,
    name: false,
    phone: false,
    privacy: false,
  });
  const dispatch = useDispatch();
  const formSubmit = useSelector((state) => state.bibliotecarioContactForm);
  const handleSubmit = () => {
    if (!(!!email && !!question && !!name && !!phone && privacy)) {
      setValidatedFields({
        email: !!email,
        question: !!question,
        name: !!name,
        phone: !!phone,
        privacy: privacy,
      });
    } else {
      // sending a mail through plone
      dispatch(
        postBibliotecarioContactForm('', {
          email,
          question,
          name,
          phone,
          privacy,
          reason,
          residence,
          age,
          sources,
        }),
      );
      setSubmitting(!submitting);
      setFormSubmitted(true);
      //   window.scrollTo({
      //     top: 600,
      //     behavior: 'smooth',
      //   });
      document.getElementById('text').scrollIntoView({
        behavior: 'smooth',
      });
    }
  };
  return (
    <div className="form-container">
      {!formSubmitted ? (
        <Form className="m-3" tag="form">
          <div className="form-group">
            <Input
              id="question"
              label={intl.formatMessage(messages.question)}
              type="textarea"
              rows="4"
              value={question}
              invalid={!question && submitting}
              onChange={(e) => setQuestion(e.target.value)}
              infoText={intl.formatMessage(messages.requiredField)}
            />
          </div>
          <Input
            id="email"
            label={intl.formatMessage(messages.email)}
            type="email"
            value={email}
            invalid={!email && submitting}
            onChange={(e) => setEmail(e.target.value)}
            infoText={intl.formatMessage(messages.requiredField)}
          />
          <Input
            id="name"
            label={intl.formatMessage(messages.name)}
            type="text"
            value={name}
            invalid={!name && submitting}
            onChange={(e) => setName(e.target.value)}
            infoText={intl.formatMessage(messages.requiredField)}
          />
          <Input
            id="phone"
            label={intl.formatMessage(messages.phone)}
            type="tel"
            value={phone}
            invalid={!phone && submitting}
            onChange={(e) => setPhone(e.target.value)}
            infoText={intl.formatMessage(messages.requiredField)}
          />
          <Input
            id="residence"
            label={intl.formatMessage(messages.residence)}
            type="text"
            value={residence}
            onChange={(e) => setResidence(e.target.value)}
          />
          <Input
            id="age"
            label={intl.formatMessage(messages.age)}
            type="number"
            value={age}
            onChange={(e) => setAge(e.target.value)}
          />
          <Input
            id="reason"
            label={intl.formatMessage(messages.reason)}
            type="text"
            value={reason}
            onChange={(e) => setReason(e.target.value)}
          />
          <div className="form-group">
            <Input
              id="sources"
              label={intl.formatMessage(messages.sources)}
              type="textarea"
              rows="4"
              value={sources}
              onChange={(e) => setSources(e.target.value)}
            />
          </div>
          <FormGroup check className="mb-4">
            <Input
              id="privacy"
              type="checkbox"
              checked={privacy}
              invalid={!privacy && submitting}
              onChange={() => setPrivacy(!privacy)}
            />
            <Label check for="privacy" tag="label">
              {intl.formatMessage(messages.privacy)}{' '}
              <a href="/privacy">{intl.formatMessage(messages.privacy_link)}</a>
            </Label>
            {!privacy && submitting && (
              <div className="invalid-feedback">
                {intl.formatMessage(messages.requiredField)}
              </div>
            )}
          </FormGroup>
          <Button
            className=""
            onClick={() => {
              setSubmitting(true);
              handleSubmit();
            }}
          >
            {intl.formatMessage(messages.send)}
          </Button>
        </Form>
      ) : (
        <div className="form-success mt-4">
          {intl.formatMessage(messages.sent)}
        </div>
      )}
    </div>
  );
};

export default ContactForm;
