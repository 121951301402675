/**
 * PaginaArgomentoView view component.
 * @module components/theme/View/PaginaArgomentoView/PaginaArgomentoView
 */

import React, { useState } from 'react';
import { Container } from 'design-react-kit/dist/design-react-kit';
import { flattenToAppURL } from '@plone/volto/helpers';
import {
  TextOrBlocks,
  RichText,
  SearchSectionForm,
  PageHeaderNav,
} from '@italia/components/ItaliaTheme/View';
import ContactForm from './ContactForm';
import { defineMessages, useIntl } from 'react-intl';
/**
 * ChiediAlBibliotecarioView view component class.
 * @function ChiediAlBibliotecarioView
 * @params {object} content Content object.
 * @returns {string} Markup of the component.
 */

const messages = defineMessages({
  inThisSection: {
    id: 'inThisSection',
    defaultMessage: 'In questa sezione',
  },
});

const ChiediAlBibliotecarioView = ({ content }) => {
  const intl = useIntl();
  const [formSubmitted, setFormSubmitted] = useState(false);
  return (
    <div id="page-document">
      <div className="ui container ask-librarian">
        <Container className="PageHeaderWrapper px-3 px-md-4 mb-4">
          <div className="row">
            <div className="title-description-wrapper col-lg-6">
              <h1 className="mb-3">{content?.title}</h1>
              <p className="description">{content?.description}</p>
              {content?.ricerca_in_testata && (
                <SearchSectionForm content={content} />
              )}
            </div>
            <div className="col-lg-4 offset-lg-2">
              {content.info_testata?.data?.replace(/<[^>]+>/g, '') && (
                <div className="header-infos px-4 mb-5">
                  <RichText serif={false} content={content.info_testata.data} />
                </div>
              )}
              {content.mostra_navigazione && (
                <PageHeaderNav
                  content={content}
                  title={intl.formatMessage(messages.inThisSection)}
                />
              )}
            </div>
          </div>
        </Container>

        {content?.image ? (
          <>
            <div className="leadimage-container">
              <img
                src={flattenToAppURL(content?.image?.download)}
                alt={content?.caption || content?.title}
                title={content?.caption || content?.title}
                className="full-width"
              />
            </div>
          </>
        ) : (
          ''
        )}

        <div className="mt-4 mb-4" id="text">
          <TextOrBlocks content={content} />
        </div>
        <ContactForm
          formSubmitted={formSubmitted}
          setFormSubmitted={setFormSubmitted}
        />
      </div>
    </div>
  );
};

export default ChiediAlBibliotecarioView;
