import { useEffect, useRef, useState } from 'react';
import {
  Container,
  Row,
  Button,
  Input,
} from 'design-react-kit/dist/design-react-kit';
import {
  PageHeader,
  SkipToMainContent,
  SideMenu,
  TextOrBlocks,
  RichTextArticle,
} from '@italia/components/ItaliaTheme/View';

const NewsletterView = ({ content }) => {
  const [sideMenuElements, setSideMenuElements] = useState(null);
  const documentBody = useRef();

  // Populate side menu
  useEffect(() => {
    if (documentBody.current) {
      if (__CLIENT__) {
        setSideMenuElements(documentBody.current);
      }
    }
  }, [documentBody]);

  return (
    <Container className="px-4 my-4 newsletter-view">
      <SkipToMainContent />
      <PageHeader
        content={content}
        readingtime={null}
        showreadingtime={false}
        showdates={false}
        showtassonomiaargomenti={false}
      />

      <Row className="border-top row-column-border row-column-menu-left">
        <aside className="col-lg-4">
          {__CLIENT__ && (
            <SideMenu data={sideMenuElements} content_uid={content?.UID} />
          )}
        </aside>
        <section
          ref={documentBody}
          id="main-content-section"
          className="col-lg-8 it-page-sections-container"
        >
          <RichTextArticle
            tag_id="iscriviti"
            title="Iscriviti alla nostra newsletter"
          >
            <form
              action="https://imola.us20.list-manage.com/subscribe/post?u=bcb553314ba5ed18368d112d6&amp;id=f594f39f63"
              method="post"
              id="mc-embedded-subscribe-form"
              name="mc-embedded-subscribe-form"
              className="validate"
              target="_blank"
              noValidate
            >
              <div id="mc_embed_signup_scroll">
                <div className="indicates-required">
                  <span className="asterisk">*</span> campo obbligatorio
                </div>
                <div className="mc-field-group mt-4">
                  <Input
                    label="Email *"
                    type="email"
                    defaultValue=""
                    name="EMAIL"
                    required={true}
                    className="email"
                    id="mce-EMAIL"
                  />
                </div>
                <div id="mce-responses" className="clear foot">
                  <div
                    className="response"
                    id="mce-error-response"
                    style={{ display: 'none' }}
                  ></div>
                  <div
                    className="response"
                    id="mce-success-response"
                    style={{ display: 'none' }}
                  ></div>
                </div>
                <div
                  style={{ position: 'absolute', left: '-5000px' }}
                  aria-hidden="true"
                >
                  <input
                    type="text"
                    name="b_bcb553314ba5ed18368d112d6_f594f39f63"
                    tabIndex="-1"
                    defaultValue=""
                  />
                </div>
                <div className="optionalParent">
                  <div className="clear foot">
                    <Button
                      type="submit"
                      name="subscribe"
                      id="mc-embedded-subscribe"
                    >
                      Invia
                    </Button>
                  </div>
                </div>
              </div>
            </form>
          </RichTextArticle>
          <div className="mt-4 mb-4" id="text">
            <TextOrBlocks content={content} />
          </div>
        </section>
      </Row>
    </Container>
  );
};

export default NewsletterView;
