import React from 'react';
import { Icon } from 'design-react-kit/dist/design-react-kit';
import { ConditionalLink } from '@plone/volto/components';
import { flattenToAppURL } from '@plone/volto/helpers';
import cx from 'classnames';

const Body = ({ data, inEditMode }) => {
  return data.link ? (
    <ConditionalLink
      condition={!inEditMode}
      to={flattenToAppURL((data.link[0] && data.link[0]['@id']) || '')}
      className="text-decoration-none"
    >
      <div
        className={cx(
          'd-flex justify-content-between align-items-center mb-3 scopri-contenuti-block mr-3',
        )}
      >
        <div className="d-flex justify-content-between align-items-center w-100 ml-5 mr-3">
          <h3>{data.link[0]?.title}</h3>
          <Icon
            className="icon-color"
            color=""
            icon="it-chevron-right"
            padding={false}
            size="lg"
          />
        </div>
      </div>
    </ConditionalLink>
  ) : null;
};

export default Body;
