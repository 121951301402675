/**
 * Customizzazione
 * Menù custom per sottosito bim
 */

import React from 'react';
import { Link } from 'react-router-dom';
import { Nav, NavItem, NavLink } from 'design-react-kit/dist/design-react-kit';
import { LanguageSelector, ArLogin } from '@italia/components/ItaliaTheme';

const HeaderSlimRightZone = () => {
  return (
    <>
      <Nav vertical={false} className="parent-site-menu d-none d-md-flex">
        <NavItem tag="li">
          <NavLink to="/cerca-nei-cataloghi" tag={Link}>
            <span>Catalogo on line</span>
          </NavLink>
        </NavItem>
        <NavItem tag="li">
          <NavLink
            to="/cosa-puoi-fare/altre-attivita/calendario-eventi"
            tag={Link}
          >
            <span>Eventi</span>
          </NavLink>
        </NavItem>
        <NavItem tag="li">
          <NavLink to="/chiedi-al-bibliotecario" tag={Link}>
            <span>Chiedi al bibliotecario</span>
          </NavLink>
        </NavItem>
        <NavItem tag="li">
          <NavLink to="/avvisi" tag={Link}>
            <span>Avvisi</span>
          </NavLink>
        </NavItem>
        <NavItem tag="li">
          <NavLink to="/orari" tag={Link}>
            <span>Orari</span>
          </NavLink>
        </NavItem>
      </Nav>

      <LanguageSelector />
      <ArLogin />
    </>
  );
};

export default HeaderSlimRightZone;
