import React from 'react';

// eslint-disable-next-line import/no-unresolved
import logo from '@package/components/ImolaTheme/Logo/bim.png?width=200';

const Logo = () => (
  <figure className="icon">
    <img src={logo} alt="" width="200px" height="92.85px" loading="lazy" />
  </figure>
);

export default Logo;
