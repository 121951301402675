import React from 'react';
import PropTypes from 'prop-types';
import loadable from '@loadable/component';
import 'react-image-gallery/styles/css/image-gallery.css';
import { flattenToAppURL } from '@plone/volto/helpers';
import { Button } from 'semantic-ui-react';
import { Icon } from '@plone/volto/components';
import galleryLeftSVG from '@plone/volto/icons/left-key.svg';
import galleryRightSVG from '@plone/volto/icons/right-key.svg';
import { useHistory } from 'react-router-dom';
import { Container } from 'design-react-kit/dist/design-react-kit';
import config from '@plone/volto/registry';

const ImageGallery = loadable(() => import('react-image-gallery'));

const renderLeftNav = (onClick, disabled) => {
  return (
    <Button
      className="image-gallery-icon image-gallery-left-nav primary basic"
      disabled={disabled}
      onClick={onClick}
    >
      <Icon name={galleryLeftSVG} size="48px" fill="black" />
    </Button>
  );
};
const renderRightNav = (onClick, disabled) => {
  return (
    <Button
      className="image-gallery-icon image-gallery-right-nav primary basic"
      disabled={disabled}
      onClick={onClick}
    >
      <Icon name={galleryRightSVG} size="48px" />
    </Button>
  );
};

const ImageGalleryTemplate = ({ items }) => {
  const history = useHistory();
  const myRef = React.createRef();
  const renderItems = items;

  const imagesInfo = renderItems.map((item) => {
    return {
      original: flattenToAppURL(
        item[config.settings.listingPreviewImageField]?.scales.large.download ||
          '',
      ),
      thumbnail: flattenToAppURL(
        item[config.settings.listingPreviewImageField]?.scales.thumb.download ||
          '',
      ),
      description: item.description ? item.description + ' \u2192' : null,
    };
  });

  return (
    renderItems.length > 0 && (
      <Container className="mb-5">
        <ImageGallery
          items={imagesInfo}
          renderLeftNav={renderLeftNav}
          renderRightNav={renderRightNav}
          lazyLoad={true}
          showFullscreenButton={false}
          showThumbnails={false}
          showPlayButton={false}
          showBullets={true}
          ref={myRef}
          onClick={() => {
            const currentItem = renderItems[myRef.current.getCurrentIndex()];
            if (!currentItem.remoteUrl || currentItem.remoteUrl === '') {
              return null;
            }

            if (currentItem.remoteUrl.includes(window.location.hostname)) {
              history.push(flattenToAppURL(currentItem.remoteUrl));
            } else {
              window.location.href = currentItem.remoteUrl;
            }
          }}
        />
      </Container>
    )
  );
};

ImageGalleryTemplate.propTypes = {
  items: PropTypes.arrayOf(PropTypes.any).isRequired,
};

export default ImageGalleryTemplate;
