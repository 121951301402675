import React from 'react';

const Background = (props) => {
  return (
    <div className="argumentInEvidence-background" {...props}>
      <div className="img-responsive-wrapper">
        <div className="img-responsive img-responsive-panoramic">
          <figure className="img-wrapper">
            {props?.image && (
              <img
                src={`data:${props?.image['content-type']};${props?.image?.encoding},${props?.image?.data}`}
                role="presentation"
                alt=""
                style={{ height: '300px' }}
                loading="lazy"
                decoding="async"
              />
            )}
          </figure>
        </div>
      </div>
    </div>
  );
};

export default Background;
