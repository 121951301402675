export const BIBLIOTECARIO_CONTACT_FORM = 'BIBLIOTECARIO_CONTACT_FORM';

/**
 * Get querystring results.
 * @function getQueryStringResults
 * @param {Object} data Data.
 * @returns {Object} Get querystringsearch results action.
 */
export function postBibliotecarioContactForm(path, data) {
  // fixes https://github.com/plone/volto/issues/1059
  return {
    type: BIBLIOTECARIO_CONTACT_FORM,
    request: {
      op: 'post',
      path: `${path}/@form-bibliotecario`,
      data: {
        ...data,
      },
    },
  };
}
