/**
 * Customizzazioni:
 * aggiunta gestione hours e location
 * import helper custom per mostrare le date nelle Rassegne
 * - always hide hours in Rassegna if it's also whole_day
 * - always hide CardCalendar component if item is an Event or a Rassegna
 */
import React from 'react';
import PropTypes from 'prop-types';
import cx from 'classnames';
import { UniversalLink } from '@plone/volto/components';
import { useIntl } from 'react-intl';
import moment from 'moment';
import 'moment/min/locales';
import {
  Container,
  Row,
  Col,
  Card,
  CardBody,
  CardTitle,
  CardText,
  Chip,
  ChipLabel,
} from 'design-react-kit/dist/design-react-kit';
import { flattenToAppURL } from '@plone/volto/helpers';
import { CardCategory, ListingLinkMore } from '@italia/components/ItaliaTheme';
import { getEventRecurrenceMore, getCalendarDate } from '@italia/helpers';
import { getCategory } from '@italia/components/ItaliaTheme/Blocks/Listing/Commons/utils';
import {
  getItemIcon,
  CardCalendar,
  ListingCategory,
  ListingImage,
  ListingText,
  CardPersona,
} from '@italia/components/ItaliaTheme';

const eventHours = (start, end) => {
  const mStart = moment(start);
  const mEnd = moment(end);
  if (!mStart.isValid() || !mEnd.isValid()) {
    return null;
  }
  const sameTime = mStart.isSame(mEnd, 'minute');
  return sameTime ? (
    <span className="time">{mStart.format('HH:mm')}</span>
  ) : (
    <span className="time">
      {mStart.format('HH:mm')} - {mEnd.format('HH:mm')}
    </span>
  );
};

const eventLocation = (event) => {
  const luogo = event?.nome_sede
    ? event.nome_sede
    : event?.luoghi_correlati?.[0];

  return luogo ? (
    <span
      className={cx('location', {
        'custom-bim-location':
          event['@type'] === 'Event' || event['@type'] === 'Rassegna',
      })}
    >
      {luogo}
    </span>
  ) : null;
};

const CardWithImageTemplate = (props) => {
  const intl = useIntl();
  moment.locale(intl.locale);

  const {
    items,
    isEditMode,
    title,
    linkTitle,
    linkHref,
    show_block_bg = false,
    always_show_image = false,
    set_four_columns = false,
    show_type = false,
    show_section,
    show_icon = true,
    show_description = true,
    show_topics = true,
    hide_dates = false,
    show_hours = false,
    show_location = false,
    natural_image_size = false,
    show_category = false,
  } = props;

  const imagesToShow = set_four_columns ? 4 : 3;
  return (
    <div className="card-with-image-template">
      <Container className="px-4">
        {title && (
          <Row>
            <Col>
              <h2 className={cx('mb-4', { 'mt-5': !show_block_bg })}>
                {title}
              </h2>
            </Col>
          </Row>
        )}
        <Row className="items">
          {items.map((item, index) => {
            const icon = show_icon ? getItemIcon(item) : null;
            const date = hide_dates ? null : getCalendarDate(item);

            // const location = show_location ? true : null;

            const hours =
              show_hours && !(item['@type'] === 'Rassegna' && item.whole_day)
                ? eventHours(item.start, item.end)
                : null;
            const location = show_location ? eventLocation(item) : null;
            const eventRecurrenceMore = hide_dates
              ? null
              : getEventRecurrenceMore(item, isEditMode);
            const listingText = show_description ? (
              <ListingText item={item} />
            ) : null;

            const image = ListingImage({ item });

            const showImage =
              (index < imagesToShow || always_show_image) && image != null;
            const category = getCategory(item, show_type, show_section, props);
            const topics = show_topics ? item.tassonomia_argomenti : null;

            return (
              <Col
                lg={set_four_columns ? '3' : '4'}
                key={item['@id']}
                className="col-item mb-3"
              >
                {item['@type'] === 'Persona' ? (
                  <CardPersona
                    item={item}
                    className="listing-item card-bg"
                    showImage={showImage}
                    natural_image_size={natural_image_size}
                    listingText={listingText}
                    icon={icon}
                    type={category}
                    isEditMode={isEditMode}
                  />
                ) : (
                  <Card
                    className={cx('listing-item card-bg', {
                      'card-img': showImage,
                      'card-teaser-image card-flex no-after':
                        item['@type'] === 'Persona',
                    })}
                  >
                    {/* wrapperClassName="card-overlapping" */}
                    {showImage && (
                      <div
                        className={cx('img-responsive-wrapper', {
                          'natural-image-size': natural_image_size,
                        })}
                      >
                        <div className="img-responsive img-responsive-panoramic">
                          <figure className="img-wrapper">{image}</figure>
                          {item['@type'] !== 'Event' &&
                            item['@type'] !== 'Rassegna' && (
                              <CardCalendar start={item.start} end={item.end} />
                            )}
                        </div>
                      </div>
                    )}
                    <CardBody>
                      {(icon || category || date) && (
                        <CardCategory
                          iconName={icon}
                          date={date}
                          hours={hours}
                          location={location}
                          show_category={show_category}
                          type={item['@type']}
                        >
                          <ListingCategory category={category} item={item} />
                        </CardCategory>
                      )}
                      <CardTitle tag="h3">
                        <UniversalLink
                          item={!isEditMode ? item : null}
                          href={isEditMode ? '#' : ''}
                        >
                          {item.title || item.id}
                        </UniversalLink>
                      </CardTitle>
                      {listingText && (
                        <CardText
                          className={cx('', {
                            'mb-3': topics?.length > 0,
                          })}
                        >
                          {listingText}
                        </CardText>
                      )}

                      {topics?.length > 0 && (
                        <div
                          className={cx('', {
                            'mb-3': eventRecurrenceMore,
                          })}
                        >
                          {topics.map((argument, index) => (
                            <UniversalLink
                              href={flattenToAppURL(argument['@id'])}
                              key={index}
                              title={argument.title}
                              className="text-decoration-none"
                            >
                              <Chip
                                color="primary"
                                disabled={false}
                                simple
                                tag="div"
                                className="mr-2"
                              >
                                <ChipLabel tag="span">
                                  {argument.title}
                                </ChipLabel>
                              </Chip>
                            </UniversalLink>
                          ))}
                        </div>
                      )}

                      {eventRecurrenceMore}
                    </CardBody>
                  </Card>
                )}
              </Col>
            );
          })}
        </Row>
        <ListingLinkMore title={linkTitle} href={linkHref} className="my-4" />
      </Container>
    </div>
  );
};

CardWithImageTemplate.propTypes = {
  items: PropTypes.arrayOf(PropTypes.any).isRequired,
  linkTitle: PropTypes.any,
  linkHref: PropTypes.any,
  isEditMode: PropTypes.bool,
  title: PropTypes.string,
};

export default CardWithImageTemplate;
