/**
 * Search filters action
 * @module src/actions/getBimFooter
 */
export const GET_BIM_FOOTER = 'GET_BIM_FOOTER';

/**
 * Get search filters.
 * @function getBimFooter
 * @returns {Object} Get search filters action.
 */
export function getBimFooter(path) {
  return {
    type: GET_BIM_FOOTER,
    request: {
      op: 'get',
      path: `${path}/@bim-footer-navtree`,
    },
  };
}
