import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { Segment, Accordion } from 'semantic-ui-react';
import { defineMessages, FormattedMessage, injectIntl } from 'react-intl';
import { TextWidget, ObjectBrowserWidget } from '@plone/volto/components';

const messages = defineMessages({
  blockStyle: {
    id: 'Block style',
    defaultMessage: 'Block style',
  },
  content: {
    id: 'Content',
    defaultMessage: 'Content',
  },
  more: {
    id: 'Link more',
    defaultMessage: 'Link more',
  },
  title: {
    id: 'Titolo',
    defaultMessage: 'Titolo',
  },
  show_block_bg: {
    id: 'Mostra lo sfondo del blocco',
    defaultMessage: 'Mostra lo sfondo del blocco',
  },
});

const Sidebar = (props) => {
  const [activeAccIndex, setActiveAccIndex] = useState(1);

  function handleAccClick(e, titleProps) {
    const { index } = titleProps;
    const newIndex = activeAccIndex === index ? -1 : index;

    setActiveAccIndex(newIndex);
  }

  return (
    <Segment.Group raised>
      <header className="header pulled">
        <h2>
          <FormattedMessage id="Listing" defaultMessage="Listing" />
        </h2>
      </header>

      <Accordion fluid styled className="form">
        <TextWidget
          id="title"
          title={props.intl.formatMessage(messages.title)}
          required={false}
          value={props.data.title}
          onChange={(name, value) => {
            props.onChangeBlock(props.block, {
              ...props.data,
              [name]: value,
            });
          }}
        />

        <ObjectBrowserWidget
          id={'ObjectBrowserWidget'}
          title={'Uffici'}
          required={true}
          mode={'multiple'}
          value={props.data.venues || []}
          onChange={(name, value) => {
            props.onChangeBlock(props.block, {
              ...props.data,
              venues: value,
            });
          }}
        />
      </Accordion>
    </Segment.Group>
  );
};

Sidebar.propTypes = {
  data: PropTypes.objectOf(PropTypes.any).isRequired,
  block: PropTypes.string.isRequired,
  onChangeBlock: PropTypes.func.isRequired,
};

export default injectIntl(Sidebar);
