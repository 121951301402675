/* eslint-disable jsx-a11y/no-static-element-interactions */
import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { useIntl } from 'react-intl';
import moment from 'moment';
import UniversalLink from '@plone/volto/components/manage/UniversalLink/UniversalLink';
import { Icon } from '@italia/components/ItaliaTheme';
import { useSelector } from 'react-redux';
import { find } from 'lodash';

const navigate = (text) => {
  window.open(
    `https://sol.unibo.it/SebinaOpac/query/${text}?sysb=IMOLA&context=catalogo`,
    '_blank',
  );
};

const Body = ({ block, sections }) => {
  const [inputText, setInputText] = useState('');
  const token = useSelector((state) => state.userSession.token);
  const editPermission = useSelector((state) =>
    find(state.actions.actions.object, { id: 'edit' }),
  );
  const isEditor = token && editPermission;

  const intl = useIntl();
  moment.locale(intl.locale);

  const backgroundImage = block.image
    ? `url(data:${block.image['content-type']};${block.image?.encoding},${block.image?.data})`
    : null;

  return (
    <div className="public-ui searchSections searchInCatalogs">
      <div className="searchSections-background" style={{ backgroundImage }} />
      <div className="container">
        <div className="searchContainer d-flex w-100">
          <h2 className="text-primary mb-4">{block.title}</h2>
          <div className="searchbar lightgrey-bg-c2 shadow-sm rounded d-flex w-100">
            <input
              className="inputSearch lightgrey-bg-c2"
              type="text"
              placeholder={block.placeholder}
              onChange={(e) => setInputText(e.currentTarget.value)}
              onKeyDown={(e) =>
                e.key === 'Enter' ? navigate(inputText) : null
              }
            ></input>
            <button
              className="rounded-right"
              onClick={(e) => navigate(inputText)}
            >
              <Icon icon="it-search" padding={false} size="sm" />
            </button>
          </div>
          <div className="buttonsContainer mt-2 d-flex">
            {block.links?.map((link, index) => {
              if (link.review_state === 'private' && !isEditor) {
                return null;
              }
              return (
                <UniversalLink
                  className="btn btn-primary btn-sm catalog-link"
                  key={index}
                  href={link['remoteUrl']}
                  title={link.title}
                >
                  {link.title}
                </UniversalLink>
              );
            })}
          </div>
        </div>
      </div>
    </div>
  );
};

/**
 * Property types.
 * @property {Object} propTypes Property types.
 * @static
 */
Body.propTypes = {
  block: PropTypes.objectOf(PropTypes.any).isRequired,
};

export default Body;
