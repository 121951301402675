/**
 * Customizzazioni:
 * Aggiunti hours e location
 */
import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import { UniversalLink } from '@plone/volto/components';
import { Icon } from '@italia/components/ItaliaTheme';

const propTypes = {
  iconName: PropTypes.string,
  date: PropTypes.any,
  location: PropTypes.any,
  href: PropTypes.string,
};

const CardCategory = (props) => {
  const {
    iconName,
    date,
    href,
    hours,
    location,
    show_category,
    ...attributes
  } = props;
  const { children, ...rest } = attributes;
  const classes = classNames({
    'category-top': date || ' ',
    'categoryicon-top': iconName,
  });
  // Simple category link
  const categoryLink = href && (
    <UniversalLink href={href} className="category">
      {children}
    </UniversalLink>
  );
  const categoryDate = date && <span className="data">{date}</span>;

  // Category with icon
  const categoryText = show_category && !href && children && (
    <span className="text">{children}</span>
  );
  const categoryIcon = iconName && <Icon icon={iconName} />;

  return (
    <div className={classes} {...rest}>
      {categoryLink}
      {categoryIcon}

      {categoryText}
      {location}
      {categoryDate}
      {hours}
    </div>
  );
};

CardCategory.propTypes = propTypes;

export default CardCategory;
