import React from 'react';
// eslint-disable-next-line import/no-unresolved
import logo from '@package/components/ImolaTheme/Logo/logo_footer_bim.png?width=200';

const BIMLogoFooter = () => (
  <figure className="bimFooterLogo">
    <img src={logo} alt="" loading="lazy" width="200px" height="95.85px" />
  </figure>
);

export default BIMLogoFooter;
