//import { useSelector } from 'react-redux';
import DefaultFilters from '@italia/components/ItaliaTheme/Blocks/EventSearch/DefaultFilters';

/*
  ***
  componente da customizzare nel proprio sito per modificare/aggiungere tipologie di Filtri
  ***
 */
const FiltersConfig = (dispatchFilter) => {
  const defaultFilters = DefaultFilters(dispatchFilter);
  defaultFilters.venue_filter.widget.props.options.dispatch.additionalParams = {
    sort_on: 'getObjPositionInParent',
  };
  return defaultFilters;
};

export default FiltersConfig;
