import React from 'react';
import { Container, Row, Col } from 'design-react-kit/dist/design-react-kit';
import BIMFooterNavigation from '@package/components/ImolaTheme/Footer/BIMFooterNavigation';
import { UniversalLink } from '@plone/volto/components';
import { FooterInfos, FooterSmall, FooterNavigation } from '@italia/components/ItaliaTheme/';
import BIMLogoFooter from '@package/components/ImolaTheme/Footer/BIMLogoFooter';
import config from '@plone/volto/registry';

const BIMFooter = () => {
  return (
    <footer className="it-footer" id="footer">
      <div className="it-footer-main">
        <Container tag="div">
          <section>
            <Row className="clearfix" tag="div">
              <Col sm={12} tag="div" widths={['xs', 'sm', 'md', 'lg', 'xl']}>
                <div className="it-brand-wrapper">
                  <UniversalLink href="/">
                    <BIMLogoFooter />
                    <div className="it-brand-text">
                      <h2 className="bim-footer">
                        {config.settings.siteProperties.siteTitle}
                      </h2>
                    </div>
                  </UniversalLink>
                </div>
              </Col>
            </Row>
          </section>
          <section>
            <FooterNavigation />
            {/* <BIMFooterNavigation subsitePath={'/'} /> */}
          </section>
          <section className="mt-4 pb-4">
            <FooterInfos />
          </section>
        </Container>
      </div>
      <FooterSmall />
    </footer>
  );
};

export default BIMFooter;
